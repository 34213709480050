import { DENIED, GOOGLE_CONSENTS_KEYS } from 'Component/GDPRPopup/GDPRPopup.config';

/** @namespace Pwa/Util/Consents/setConsents */
export const setConsents = (
    action,
    adStorage = DENIED,
    adUserData = DENIED,
    adPersonalization = DENIED,
    adAnalytics = DENIED
) => {
    if (window?.gtag) {
        window.gtag('consent', action, {
            ad_storage: adStorage,
            ad_user_data: adUserData,
            ad_personalization: adPersonalization,
            analytics_storage: adAnalytics,
        });
    }
};

/** @namespace Pwa/Util/Consents/resetConsentsStorage */
export const resetConsentsStorage = () => {
    localStorage.setItem(GOOGLE_CONSENTS_KEYS.ad_storage, DENIED);
    localStorage.setItem(GOOGLE_CONSENTS_KEYS.ad_user_data, DENIED);
    localStorage.setItem(GOOGLE_CONSENTS_KEYS.ad_personalization, DENIED);
    localStorage.setItem(GOOGLE_CONSENTS_KEYS.analytics_storage, DENIED);
};
