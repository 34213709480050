import { OrderQuery as SourceOrderQuery } from 'SourceQuery/Order.query';
import { Field } from 'Util/Query';

/** @namespace Pwa/Query/Order/Query/OrderQuery */
export class OrderQuery extends SourceOrderQuery {
    getPurchaseQuery(orderID) {
        return new Field('getPurchase')
            .addArgument('orderID', 'String', orderID)
            .addFieldList([this._getPurchaseProductField(), this._getRevenueField()]);
    }

    _getOrderItemProductsFields() {
        return [
            ...super._getOrderItemProductsFields(),
            this._getOrderProductSalePriceInclTaxField(),
            this._getOrderProductRowSubtotalInclTaxField(),
        ];
    }

    _getOrderProductSalePriceInclTaxField() {
        return new Field('product_sale_price_incl_tax').addFieldList(this._getOrderPriceFields());
    }

    _getOrderProductRowSubtotalInclTaxField() {
        return new Field('row_subtotal_incl_tax').addFieldList(this._getOrderPriceFields());
    }

    _getOrderItemTotalFields() {
        return [...super._getOrderItemTotalFields(), this._getOrderSubtotalField()];
    }

    _getOrderSubtotalField() {
        return new Field('subtotal_incl_tax').addFieldList(this._getOrderPriceFields());
    }

    _getPurchaseProductField() {
        return new Field('purchaseProducts').setAlias('products').addFieldList(this._getPurchaseProductFields());
    }

    _getPurchaseProductFields() {
        return ['id', 'price', 'quantity'];
    }

    _getRevenueField() {
        return new Field('revenue');
    }
}

export default new OrderQuery();
