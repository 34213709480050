import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import { THANK_YOU_PAGE } from 'Route/ThankYouPage/ThankYouPage.config';
import { isHomePageUrl } from 'Util/Url';

import HeaderFollowing from './HeaderFollowing.component';

/** @namespace Pwa/Component/HeaderFollowing/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
    pageType: state.UrlRewritesReducer.urlRewrite.type,
    product: state.ProductReducer?.product,
});

/** @namespace Pwa/Component/HeaderFollowing/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Pwa/Component/HeaderFollowing/Container/HeaderFollowingContainer */
export class HeaderFollowingContainer extends PureComponent {
    containerProps() {
        return {
            ...this.props,
            pageType: this.getRealPageType(),
        };
    }

    getRealPageType() {
        const {
            history: {
                location: { pathname },
            },
            pageType,
        } = this.props;

        if (isHomePageUrl(pathname)) {
            return 'HOMEPAGE';
        }
        if (pathname.includes('/compare')) {
            return 'COMPARE_PAGE';
        }

        if (pathname.includes(CHECKOUT_URL)) {
            return 'CHECKOUT';
        }

        if (pathname.includes(THANK_YOU_PAGE)) {
            return 'THANK_YOU_PAGE';
        }

        return pageType;
    }

    render() {
        return <HeaderFollowing {...this.containerProps()} />;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderFollowingContainer));
