import React, { createRef, PureComponent } from 'react';
import { Helmet } from 'react-helmet';

import { CENEO_WIDGET_CLOSED, CENEO_WIDGET_ID } from './Ceneo.config';

/** @namespace Pwa/Component/Ceneo/Component/Ceneo */
export class Ceneo extends PureComponent {
    ceneoScriptRef = createRef();

    componentDidMount() {
        if (localStorage.getItem(CENEO_WIDGET_CLOSED)) {
            this.createPlaceholder();
        }
    }

    componentWillUnmount() {
        if (this.ceneoScriptRef.current) {
            document.body.removeChild(this.ceneoScriptRef.current);
        }
    }

    createPlaceholder() {
        const isCeneoWidget = !!document?.getElementById(CENEO_WIDGET_ID);

        if (isCeneoWidget) {
            return null;
        }

        const placeholder = document.createElement('div');
        placeholder.id = CENEO_WIDGET_ID;
        document.body.appendChild(placeholder);
    }

    renderAdditional() {
        const { orderID, email, products, workDaysToSendQuestionnaire, isHeader, currency, revenue } = this.props;

        if (isHeader) {
            return null;
        }

        const ceneoProducts = products?.map(({ id, price, quantity }) => ({
            id,
            price: +price,
            quantity,
            currency,
        }));

        const __html = `_ceneo('transaction', {
            client_email: '${email}',
            order_id: '${orderID}',
            shop_products: ${JSON.stringify(ceneoProducts)},
            work_days_to_send_questionnaire: ${workDaysToSendQuestionnaire}, 
            amount: ${revenue}
        })`;

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.innerHTML = __html;

        document.body.appendChild(script);

        this.ceneoScriptRef.current = script;

        return null;
    }

    renderBasicScript() {
        const { accountID, isHeader } = this.props;
        const scriptContent = `(function(w,d,s,i,dl){w._ceneo = w._ceneo || function () {
            w._ceneo.e = w._ceneo.e || []; w._ceneo.e.push(arguments); };
            w._ceneo.e = w._ceneo.e || [];dl=dl===undefined?"dataLayer":dl;
            const f = d.getElementsByTagName(s)[0], j = d.createElement(s); j.defer = true;
            j.src = "https://ssl.ceneo.pl/ct/v5/script.js?accountGuid=" + i + "&t=" + Date.now() + (dl ? "&dl=" + dl : ''); f.parentNode.insertBefore(j, f);
            })(window, document, "script", "${accountID}");`;

        if (!isHeader) {
            return null;
        }

        return (
            <Helmet>
                <script defer type="text/javascript">
                    {scriptContent}
                </script>
            </Helmet>
        );
    }

    render() {
        const { isLoaded } = this.props;

        return (
            <>
                {this.renderBasicScript()}
                {isLoaded ? this.renderAdditional() : null}
            </>
        );
    }
}

export default Ceneo;
