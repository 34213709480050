import isEmpty from 'lodash/isEmpty';
import React, { PureComponent } from 'react';

import AddToCart from 'Component/AddToCart';
import { ARRANGEMENT_CMS_LINK } from 'Component/ArrangementProductPrice/ArrangementProductPrice.config';
import ContentWrapper from 'Component/ContentWrapper';
import Icons from 'Component/Icons';
import Image from 'Component/Image';
import Link from 'Component/Link';
import { ARRANGEMENT_PRODUCT_TYPE_ID } from 'Component/ProductHeader/ProductHeader.config';
import { formatPrice } from 'Util/Price';
import { Debouncer } from 'Util/Request';

import scssVariables from '../../style/abstract/_export.module.scss';
import { OBSERVE_TIMEOUT } from './HeaderFollowing.config';

import './HeaderFollowing.style';

/** @namespace Pwa/Component/HeaderFollowing/Component/HeaderFollowing */
export class HeaderFollowing extends PureComponent {
    observer;

    // will render layer after hooked element is over top viewport border
    layers = [
        {
            id: 'HEADER',
            hookSelector: '.Header',
            render: this.renderMainHeader.bind(this),
            renderMobile: () => null,
            canRender: ({ pageType }) => !['CHECKOUT', 'THANK_YOU_PAGE'].includes(pageType),
            emitVisibility: true,
        },
        {
            id: 'ADD_TO_CART_LAYER',
            hookSelector: '.ProductPage .ProductActions-AddToCartWrapper .AddToCart',
            render: this.renderAddToCartLayer.bind(this),
            renderMobile: this.renderAddToCartLayerMobile.bind(this),
            canRender: ({ pageType }) => pageType === 'PRODUCT',
        },
        {
            id: 'ASK_ABOUT_ARRANGEMENT_LAYER',
            hookSelector: '.ProductPage_ArrangementPage .Product-AddToCart.Button',
            render: this.renderAskAboutArrangementLayer.bind(this),
            renderMobile: this.renderAskAboutArrangementLayerMobile.bind(this),
            canRender: ({ pageType }) => pageType === 'PRODUCT',
        },
    ];

    state = {
        isMenuVisible: false,
        layersVisibility: {
            HEADER: false,
            ADD_TO_CART_LAYER: false,
            ASK_ABOUT_ARRANGEMENT_LAYER: false,
        },
    };

    debouncedToggleMenuVisible = new Debouncer();

    componentDidMount() {
        const { history } = this.props;

        this.initObserve();

        history.listen(() => {
            this.initObserve(true);
        });
    }

    componentDidUpdate(prevProps) {
        const { pageType, product } = this.props;

        // the following cover all cases where history change is not enough
        if (
            pageType !== prevProps.pageType ||
            (product && prevProps.product && product?.id !== prevProps.product?.id)
        ) {
            this.initObserve(true);
        }
    }

    initObserve(withReset) {
        if (withReset) {
            this.resetVisibility();
        }

        setTimeout(this.listenHooksAreOverTop.bind(this), OBSERVE_TIMEOUT);
    }

    listenHooksAreOverTop() {
        const { pageType } = this.props;

        this.layers.forEach((layer) => {
            if (!layer.canRender({ pageType })) {
                return null;
            }

            const callback = (entries) => {
                entries.forEach((entry) => {
                    if (entry.boundingClientRect.bottom < 0) {
                        this.toggleLayerVisible(layer.id, true);
                    } else {
                        this.toggleLayerVisible(layer.id, false);
                    }
                });
            };

            const elem = document.querySelector(layer.hookSelector);

            if (elem) {
                layer.observer = new IntersectionObserver(callback, {});
                layer.observer.observe(elem);
            }
        });
    }

    toggleLayerVisible(id, visible) {
        const { onEmitVisibility } = this.props;

        this.setState((state) => ({ layersVisibility: { ...state.layersVisibility, [id]: visible } }));

        if (this.layers.find((layer) => layer.id === id).emitVisibility && onEmitVisibility) {
            onEmitVisibility(id, visible);
        }

        if (id === 'HEADER' && !visible) {
            this.toggleMenuVisible(false);
        }
    }

    toggleMenuVisible(value) {
        this.setState((state) => ({ isMenuVisible: typeof value === 'boolean' ? value : !state.isMenuVisible }));
    }

    resetVisibility() {
        this.setState({ isMenuVisible: false, layersVisibility: {} });
    }

    renderMenuSwitch() {
        const { isMenuVisible } = this.state;

        return (
            <>
                <div
                    role="button"
                    tabIndex="-1"
                    block="HeaderFollowing"
                    elem="MenuSwitch"
                    aria-label={isMenuVisible ? __('Hide menu') : __('Show menu')}
                    mods={{ isActive: isMenuVisible }}
                    onMouseEnter={() => {
                        this.debouncedToggleMenuVisible.startDebounce(this.toggleMenuVisible.bind(this), 500)(true);
                    }}
                >
                    <Icons name="burger" fill={isMenuVisible ? scssVariables.white : scssVariables.colorPrimaryLight} />
                    {isMenuVisible && <div block="HeaderFollowing" elem="MenuSwitchBridge" />}
                </div>
                {isMenuVisible && (
                    <div
                        block="HeaderFollowing"
                        elem="MenuSwitchCursorCatchLayer"
                        onMouseEnter={() => {
                            // eslint-disable-next-line prettier/prettier
                            this.debouncedToggleMenuVisible.startDebounce(this.toggleMenuVisible.bind(this), 500)(false);
                        }}
                        onMouseLeave={() => {
                            this.debouncedToggleMenuVisible.cancelDebounce();
                        }}
                    />
                )}
            </>
        );
    }

    renderMainHeader(isVisible) {
        const { isMenuVisible } = this.state;
        const { renderIcons, renderLogo, renderMenu, renderSearchField } = this.props;

        return (
            <div block="HeaderFollowing" elem="MainHeader">
                <ContentWrapper
                    mix={{
                        block: 'HeaderFollowing',
                        elem: 'Layer',
                        mods: { type: 'HEADER', isVisible, isSublayerExpanded: isMenuVisible },
                    }}
                >
                    <div block="HeaderFollowing" elem="StartContainer">
                        {this.renderMenuSwitch()}
                        {renderLogo()}
                    </div>
                    <div block="HeaderFollowing" elem="MiddleContainer">
                        {isVisible ? renderSearchField() : null}
                    </div>
                    <div block="HeaderFollowing" elem="EndContainer">
                        <div block="HeaderFollowing" elem="Icons">
                            {isVisible && renderIcons.map((renderFunc) => renderFunc(true, '', true))}
                        </div>
                    </div>
                </ContentWrapper>
                <div block="HeaderFollowing" elem="MenuWrapper" mods={{ isVisible: isMenuVisible }}>
                    {renderMenu({ prefixMenu: '.HeaderFollowing ' })}
                </div>
            </div>
        );
    }

    renderAddToCartLayer(isVisible) {
        const { isMenuVisible } = this.state;
        const { product } = this.props;

        if (isEmpty(product)) {
            return;
        }

        const { price_range } = product;

        return (
            <ContentWrapper
                mix={{
                    block: 'HeaderFollowing',
                    elem: 'Layer',
                    mods: { isVisible: isVisible && !isMenuVisible, type: 'ADD_TO_CART_LAYER', isMobile: false },
                }}
            >
                <div block="HeaderFollowing" elem="Product">
                    <Image
                        mix={{ block: 'HeaderFollowing', elem: 'ProductImage' }}
                        ratio="custom"
                        src={(product.thumbnail || product.small_image)?.url}
                    />
                    <span block="HeaderFollowing" elem="ProductName">
                        {product.name}
                    </span>
                </div>
                <div block="HeaderFollowing" elem="Actions">
                    <span
                        block="HeaderFollowing"
                        elem="Price"
                        mods={{ isPromo: price_range.minimum_price.discount.amount_off > 0 }}
                    >
                        {formatPrice(
                            price_range.minimum_price.final_price.value,
                            price_range.minimum_price.final_price.currency
                        )}
                    </span>
                    <AddToCart product={product} quantity={1} />
                    <span
                        block="HeaderFollowing"
                        elem="BackToTop"
                        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    >
                        <Icons name="chevron_double" />
                    </span>
                </div>
            </ContentWrapper>
        );
    }

    renderAddToCartLayerMobile(isVisible) {
        const { product, product: { type_id } = {} } = this.props;
        const isArrangement = type_id === ARRANGEMENT_PRODUCT_TYPE_ID;

        if (isArrangement) {
            return null;
        }

        return (
            <ContentWrapper
                mix={{
                    block: 'HeaderFollowing',
                    elem: 'LayerBottom',
                    mods: { isVisible, type: 'ADD_TO_CART_LAYER', isMobile: true },
                }}
            >
                <AddToCart product={product} quantity={1} />
            </ContentWrapper>
        );
    }

    renderAskAboutArrangementLayer(isVisible) {
        const { isMenuVisible } = this.state;
        const { product } = this.props;

        if (isEmpty(product)) {
            return;
        }

        return (
            <ContentWrapper
                mix={{
                    block: 'HeaderFollowing',
                    elem: 'Layer',
                    mods: {
                        isVisible: isVisible && !isMenuVisible,
                        type: 'ASK_ABOUT_ARRANGEMENT_LAYER',
                        isMobile: false,
                    },
                }}
            >
                <div block="HeaderFollowing" elem="Product">
                    <Image
                        mix={{ block: 'HeaderFollowing', elem: 'ProductImage' }}
                        ratio="custom"
                        src={(product.thumbnail || product.small_image)?.url}
                    />
                    <span block="HeaderFollowing" elem="ProductName">
                        {product.name}
                    </span>
                </div>
                <div block="HeaderFollowing" elem="Actions">
                    <div block="HeaderFollowing" elem="AskAboutButton">
                        <Link className="Button" to={ARRANGEMENT_CMS_LINK}>
                            {__('Order your design for FREE')}
                        </Link>
                    </div>
                    <span
                        block="HeaderFollowing"
                        elem="BackToTop"
                        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    >
                        <Icons name="chevron_double" />
                    </span>
                </div>
            </ContentWrapper>
        );
    }

    renderAskAboutArrangementLayerMobile(isVisible) {
        return (
            <ContentWrapper
                mix={{
                    block: 'HeaderFollowing',
                    elem: 'LayerBottom',
                    mods: { isVisible, type: 'ASK_ABOUT_ARRANGEMENT_LAYER', isMobile: true },
                }}
            >
                <div block="Product" elem="ButtonsWrapper">
                    <Link className="Product-AddToCart Button" to={ARRANGEMENT_CMS_LINK}>
                        {__('Order your design for FREE')}
                    </Link>
                </div>
            </ContentWrapper>
        );
    }

    renderLayers() {
        const { layersVisibility } = this.state;
        const { device, pageType } = this.props;

        return this.layers.map((layer) => {
            const isVisible = layersVisibility[layer.id];

            if (!layer.canRender({ pageType })) {
                return null;
            }

            return device.isMobile ? layer.renderMobile(isVisible) : layer.render(isVisible);
        });
    }

    render() {
        const { isFollowingVisible } = this.state;

        return (
            <div block="HeaderFollowing" mods={{ isVisible: isFollowingVisible }}>
                {this.renderLayers()}
            </div>
        );
    }
}

export default HeaderFollowing;
