import CartIcon from 'Component/CartIcon';
import HeartIcon from 'Component/HeartIcon';
import HomeIcon from 'Component/HomeIcon';
import Link from 'Component/Link';
import { CALCULATOR_UNIT } from 'Component/ProductActions/ProductActions.config';
import SearchIcon from 'Component/SearchIcon';
import UserIcon from 'Component/UserIcon';
import { NavigationTabs as SourceNavigationTabs } from 'SourceComponent/NavigationTabs/NavigationTabs.component';
import { divideValues } from 'Util/Product/Calculator';

import { MENU_TAB, WISHLIST_TAB } from './NavigationTabs.config';

import './NavigationTabs.override.style';

/** @namespace Pwa/Component/NavigationTabs/Component/NavigationTabs */
export class NavigationTabs extends SourceNavigationTabs {
    __construct(props) {
        super.__construct(props);

        this.stateMap[WISHLIST_TAB] = {
            wishlist: true,
        };
    }

    renderMap = {
        home: this.renderHomeButton.bind(this),
        menu: this.renderMenuButton.bind(this),
        minicart: this.renderMinicartButton.bind(this),
        wishlist: this.renderWishlistButton.bind(this),
        account: this.renderAccountButton.bind(this),
    };

    shouldComponentUpdate(nextProps) {
        const {
            navigationState: { name: prevName },
            cartTotals: { items_qty: prevQty },
            device: prevDevice,
            compareTotals: prevCompareTotals,
            wishlistTotals: prevWishlistTotals,
        } = this.props;

        const {
            navigationState: { name: nextName },
            cartTotals: { items_qty: nextQty },
            device: nextDevice,
            compareTotals: nextCompareTotals,
            prevWishlistTotals: nextWishlistTotals,
        } = nextProps;

        return (
            prevName !== nextName ||
            nextQty !== prevQty ||
            prevDevice !== nextDevice ||
            prevCompareTotals !== nextCompareTotals ||
            prevWishlistTotals !== nextWishlistTotals
        );
    }

    calculateMiniCartQty(items) {
        // eslint-disable-next-line fp/no-let
        let quantity = 0;

        // eslint-disable-next-line array-callback-return
        items.map(({ product, qty }) => {
            if (product?.product_unit === 'QM') {
                const vp_items_ratio = product?.vp_items;
                const productQuantity = divideValues(qty, vp_items_ratio);

                quantity += +productQuantity;
            } else {
                quantity += +qty;
            }
        });

        return quantity;
    }

    renderCompareCount() {
        const { compareTotals } = this.props;

        if (!compareTotals) {
            return null;
        }

        return (
            <span aria-label={compareTotals} block="NavigationTabs" elem="CompareCount">
                {compareTotals}
            </span>
        );
    }

    renderWishlistCount() {
        const { wishlistTotals } = this.props;

        if (!wishlistTotals) {
            return null;
        }

        return (
            <span aria-label={wishlistTotals} block="NavigationTabs" elem="WishlistCount">
                {wishlistTotals}
            </span>
        );
    }

    renderWishlistButton(isActive = false) {
        const { onWishlistButtonClick } = this.props;

        return (
            <button
                key="wishlist"
                block="NavigationTabs"
                elem="Button"
                aria-label={__('Wishlist')}
                onClick={onWishlistButtonClick}
            >
                <span block="NavigationTabs" elem="IconWrapper">
                    <HeartIcon isActive={isActive} />
                    {this.renderWishlistCount()}
                </span>
                <span block={isActive ? 'isActive' : ''}>{__('Wishlist')}</span>
            </button>
        );
    }

    renderMenuButton(isActive = false) {
        const { onMenuButtonClick } = this.props;

        return (
            <button
                key="menu"
                block="NavigationTabs"
                elem="Button"
                aria-label={__('Categories / Search')}
                onClick={onMenuButtonClick}
            >
                <span block="NavigationTabs" elem="IconWrapper">
                    <SearchIcon isActive={isActive} />
                    {this.renderCompareCount()}
                </span>
                <span block={isActive ? 'isActive' : ''}>{__('Categories / Search')}</span>
            </button>
        );
    }

    renderHomeButton(isActive = false) {
        const { onHomeButtonClick } = this.props;

        return (
            <button
                key="home"
                block="NavigationTabs"
                elem="Button"
                aria-label={__('Start')}
                onClick={onHomeButtonClick}
            >
                <HomeIcon isActive={isActive} />
                <span block={isActive ? 'isActive' : ''}>{__('Start')}</span>
            </button>
        );
    }

    renderMinicartItemsQty() {
        const {
            cartTotals: { items, items_qty },
        } = this.props;

        if (!items_qty) {
            return null;
        }

        const isProductWithCalculatorInBasket = items
            .map(({ product: { product_unit } }) => {
                if (product_unit === CALCULATOR_UNIT) {
                    return true;
                }

                return false;
            })
            .includes(true);

        const cartQuantity = isProductWithCalculatorInBasket ? this.calculateMiniCartQty(items) : items_qty;

        return (
            <span aria-label="Items in cart" block="Header" elem="MinicartItemCount">
                {cartQuantity}
            </span>
        );
    }

    renderMinicartButton(isActive = false) {
        const { onMinicartButtonClick } = this.props;

        return (
            <button
                key="mincart"
                block="NavigationTabs"
                elem="Button"
                onClick={onMinicartButtonClick}
                aria-label={__('Cart')}
            >
                <div block="Header" elem="MinicartWrapper">
                    <div
                        block="Header"
                        elem="Button"
                        mix={{
                            block: 'NavigationTabs',
                            elem: 'Icon',
                            mods: { isActive },
                        }}
                        mods={{ isVisible: true, type: 'minicart' }}
                    >
                        <CartIcon isActive={isActive} />
                        <span block={isActive ? 'isActive' : ''}>{__('Cart')}</span>
                    </div>
                    {this.renderMinicartItemsQty()}
                </div>
            </button>
        );
    }

    renderAccountButton(isActive = false) {
        const { onMyAccountButtonClick } = this.props;

        return (
            <button
                key="account"
                block="NavigationTabs"
                elem="Button"
                onClick={onMyAccountButtonClick}
                aria-label={__('Account')}
            >
                <UserIcon isActive={isActive} />
                <span block={isActive ? 'isActive' : ''}>{__('Account')}</span>
            </button>
        );
    }

    renderCompareTotals() {
        const {
            navigationState: { name },
            compareTotals,
        } = this.props;

        if (name !== MENU_TAB || !compareTotals || compareTotals === 0) {
            return null;
        }

        return (
            <section block="NavigationTabs" elem="CompareSection">
                <Link to="compare">
                    {__('Product compare')}({compareTotals})
                </Link>
            </section>
        );
    }

    render() {
        const {
            navigationState: { isHidden },
            device,
        } = this.props;

        if (!device.isMobile) {
            return null;
        }

        return (
            <footer block="NavigationTabs" mods={{ isHidden }} mix={{ block: 'FixedElement', elem: 'Bottom' }}>
                {this.renderCompareTotals()}
                <nav block="NavigationTabs" elem="Nav">
                    {this.renderNavigationState()}
                </nav>
            </footer>
        );
    }
}

export default NavigationTabs;
