import PropTypes from 'prop-types';

import { SearchIcon as SourceSearchIcon } from 'SourceComponent/SearchIcon/SearchIcon.component';

import './SearchIcon.style';

/** @namespace Pwa/Component/SearchIcon/Component/SearchIcon */
export class SearchIcon extends SourceSearchIcon {
    static propTypes = {
        isActive: PropTypes.bool,
    };

    static defaultProps = {
        isActive: false,
    };

    render() {
        const { isActive } = this.props;

        return (
            <svg
                block="SearchIcon"
                mods={{ isActive }}
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="24"
                viewBox="0 0 33 24"
                fill="none"
            >
                <path
                    d="M0 2.69388C0 2.28812 0.328934 1.95918 0.734694 1.95918H13.9592C14.3649 1.95918 14.6939 2.28812 14.6939 2.69388C14.6939 3.09964 14.3649 3.42857 13.9592 3.42857H0.734694C0.328934 3.42857 0 3.09964 0 2.69388Z"
                    fill="currentColor"
                />
                <path
                    d="M0 9.55102C0 9.14526 0.328934 8.81633 0.734694 8.81633H13.9592C14.3649 8.81633 14.6939 9.14526 14.6939 9.55102C14.6939 9.95678 14.3649 10.2857 13.9592 10.2857H0.734694C0.328934 10.2857 0 9.95678 0 9.55102Z"
                    fill="currentColor"
                />
                <path
                    d="M0 16.4082C0 16.0024 0.328934 15.6735 0.734694 15.6735H21.7959C22.2017 15.6735 22.5306 16.0024 22.5306 16.4082C22.5306 16.8139 22.2017 17.1429 21.7959 17.1429H0.734694C0.328934 17.1429 0 16.8139 0 16.4082Z"
                    fill="currentColor"
                />
                <path
                    d="M0 23.2653C0 22.8595 0.328934 22.5306 0.734694 22.5306H21.7959C22.2017 22.5306 22.5306 22.8595 22.5306 23.2653C22.5306 23.6711 22.2017 24 21.7959 24H0.734694C0.328934 24 0 23.6711 0 23.2653Z"
                    fill="currentColor"
                />
                <path
                    d="M26.4294 12.9395C26.784 12.7423 27.2314 12.8699 27.4286 13.2245L29.9266 17.5562L32.4126 21.9865C32.6098 22.3411 32.4348 22.7433 32.0802 22.9405C31.7256 23.1377 31.2782 23.0101 31.081 22.6555L26.1444 13.9386C25.9472 13.584 26.0748 13.1367 26.4294 12.9395Z"
                    fill="currentColor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.0204 14.6939C27.078 14.6939 30.3673 11.4045 30.3673 7.34694C30.3673 3.28934 27.078 0 23.0204 0C18.9628 0 15.6735 3.28934 15.6735 7.34694C15.6735 11.4045 18.9628 14.6939 23.0204 14.6939ZM23.0204 12.9652C26.1233 12.9652 28.6387 10.4498 28.6387 7.34694C28.6387 4.24407 26.1233 1.72869 23.0204 1.72869C19.9175 1.72869 17.4022 4.24407 17.4022 7.34694C17.4022 10.4498 19.9175 12.9652 23.0204 12.9652Z"
                    fill="currentColor"
                />
            </svg>
        );
    }
}

export default SearchIcon;
