import { UPDATE_CONFIG_DATA } from './TradeDoublerConfig.action';

/** @namespace Pwa/Store/TradeDoublerConfig/Reducer/getInitialState */
export const getInitialState = () => ({
    configData: {
        products: [],
        orderId: '',
        orderValue: '',
        pageType: 'other',
    },
});

/** @namespace Pwa/Store/TradeDoublerConfig/Reducer/TradeDoublerConfigReducer */
export const TradeDoublerConfigReducer = (state = getInitialState(), action) => {
    switch (action.type) {
        case UPDATE_CONFIG_DATA:
            const { configData } = action;

            return {
                ...state,
                configData,
            };

        default:
            return state;
    }
};

export default TradeDoublerConfigReducer;
