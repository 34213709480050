import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import OrderQuery from 'Query/Order.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { fetchQuery, getErrorMessage } from 'Util/Request';

import Ceneo from './Ceneo.component';
import { ACCOUNT_GUID, WORK_DAYS_TO_SEND_QUESTIONNAIRE } from './Ceneo.config';

/** @namespace Pwa/Component/Ceneo/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, msg, debug) => dispatch(showNotification(type, msg, debug)),
});
/** @namespace Pwa/Component/Ceneo/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    currency: state.ConfigReducer.currencyData.current_currency_code,
});

/** @namespace Pwa/Component/Ceneo/Container/CeneoContainer */
export class CeneoContainer extends PureComponent {
    static propTypes = {
        email: PropTypes.string.isRequired,
        orderID: PropTypes.string.isRequired,
    };

    state = {
        isLoaded: false,
        products: [],
        revenue: '',
    };

    componentDidMount() {
        this.handlePurchase();
    }

    componentDidUpdate(prevProps) {
        const { orderID } = this.props;
        const { orderID: prevOrderID } = prevProps;

        if (orderID !== prevOrderID) {
            this.handlePurchase();
        }
    }

    async handlePurchase() {
        const { orderID } = this.props;

        if (!orderID) {
            return;
        }

        try {
            const { getPurchase } = await fetchQuery(OrderQuery.getPurchaseQuery(orderID));
            const { products = [], revenue } = getPurchase || {};

            this.setState({
                isLoaded: true,
                products,
                revenue: +Number(revenue).toFixed(2),
            });
        } catch (error) {
            showNotification('error', getErrorMessage(error), error);
            return Promise.resolve();
        }
    }

    containerProps() {
        const { orderID, email, isHeader, currency } = this.props;
        const { isLoaded, products, revenue } = this.state;

        return {
            isHeader,
            currency,
            revenue,
            isLoaded,
            orderID,
            email,
            products,
            accountID: ACCOUNT_GUID,
            workDaysToSendQuestionnaire: WORK_DAYS_TO_SEND_QUESTIONNAIRE,
        };
    }

    render() {
        return <Ceneo {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CeneoContainer);
