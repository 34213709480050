import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { DENIED, GOOGLE_CONSENTS_KEYS, GRANTED } from 'Component/GDPRPopup/GDPRPopup.config';
import {
    ACC_LOGIN_LINK,
    CART_PAGE_URL,
    FILTER,
    PAYMENT_PAGE_URL,
    TDUID_URL_PARAM,
} from 'Component/Header/Header.config';
import { SEARCH_URL, SEARCHING_URL } from 'Route/CategoryPage/CategoryPage.config.js';
import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import { THANK_YOU_PAGE } from 'Route/ThankYouPage/ThankYouPage.config';
import {
    DEFAULT_HEADER_STATE,
    HeaderContainer as SourceHeaderContainer,
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
} from 'SourceComponent/Header/Header.container';
import TradeDoublerConfigReducer from 'Store/TradeDoublerConfig/TradeDoublerConfig.reducer';
import { setConsents } from 'Util/Consents';
import { withReducers } from 'Util/DynamicReducer';
import browserHistory from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import { SUCCESS_PAGE_URL } from './Header.config';

/** @namespace Pwa/Component/Header/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    wishlistProducts: state.WishlistReducer.productsInWishlist,
    totalItems: state.ProductListReducer.totalItems,
    device: state.ConfigReducer.device,
    category: state.CategoryReducer?.category,
    configData: state.TradeDoublerConfigReducer?.configData,
});

export { DEFAULT_HEADER_STATE, mapDispatchToProps };

/** @namespace Pwa/Component/Header/Container/HeaderContainer */
export class HeaderContainer extends SourceHeaderContainer {
    componentDidMount() {
        this.handleHeaderVisibility();
        super.componentDidMount();
        this.getTDUID();
        this.getUserConsents();
    }

    getUserConsents() {
        const isConsentsSelected = !!localStorage.getItem('consentsSelected');
        const isAllConsentsAccepted = !!localStorage.getItem('allConsentsAccepted');
        const adStorageConsent = localStorage.getItem(GOOGLE_CONSENTS_KEYS.ad_storage) || DENIED;
        const adUserDataConsent = localStorage.getItem(GOOGLE_CONSENTS_KEYS.ad_user_data) || DENIED;
        const adPersonalizationConsent = localStorage.getItem(GOOGLE_CONSENTS_KEYS.ad_personalization) || DENIED;
        const analyticsStorage = localStorage.getItem(GOOGLE_CONSENTS_KEYS.analytics_storage) || DENIED;

        if (!isConsentsSelected) {
            setConsents('default');
            return null;
        }

        if (isAllConsentsAccepted) {
            setConsents('update', GRANTED, GRANTED, GRANTED, GRANTED);
            return null;
        }

        setConsents('update', adStorageConsent, adUserDataConsent, adPersonalizationConsent, analyticsStorage);
    }

    getTDUID() {
        const isTDAffiliatedParam = window?.location?.href?.includes(TDUID_URL_PARAM);

        if (sessionStorage?.TDUID || !isTDAffiliatedParam) {
            return null;
        }

        sessionStorage.setItem('TDUID', true);
    }

    containerProps() {
        const {
            location: { pathname },
        } = browserHistory;

        const { device, totalItems, wishlistProducts, category, configData } = this.props;
        const isCheckout = pathname.includes(CHECKOUT_URL) || pathname.includes(THANK_YOU_PAGE);
        const isSuccessPage = pathname.includes(SUCCESS_PAGE_URL);
        const isCartPage = pathname.includes(CART_PAGE_URL);
        const isCategoryPage = !!category?.id && !pathname?.includes(SEARCHING_URL) && !pathname?.includes(SEARCH_URL);
        const isPaymentPage = pathname.includes(PAYMENT_PAGE_URL);

        return {
            ...super.containerProps(),
            wishlistTotals: Object.keys(wishlistProducts)?.length || 0,
            totalItems,
            device,
            isCheckout,
            isCategoryPage,
            configData,
            isCartPage,
            isSuccessPage,
            isPaymentPage,
        };
    }

    containerFunctions = {
        ...this.containerFunctions,
        onCloseButtonClick: this.onCloseButtonClick.bind(this),
        onBackButtonClick: this.onBackButtonClick.bind(this),
    };

    onCloseButtonClick(e) {
        const {
            navigationState: { name },
        } = this.props;

        if (name === FILTER) {
            super.onCloseButtonClick(e);
        } else {
            const { pathname } = location;

            browserHistory.push(appendWithStoreCode(ACC_LOGIN_LINK));

            if (pathname === appendWithStoreCode(ACC_LOGIN_LINK) || pathname === ACC_LOGIN_LINK) {
                browserHistory.push(appendWithStoreCode('/'));
            }
        }
    }

    onBackButtonClick(e) {
        const {
            navigationState: { onBackClick },
        } = this.props;

        this.setState({ searchCriteria: '' });

        if (onBackClick) {
            onBackClick(e);
        }
    }
}

export default withReducers({
    TradeDoublerConfigReducer,
})(withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderContainer)));
