import { Suspense } from 'react';

import ClickOutside from 'Component/ClickOutside';
import Icons from 'Component/Icons';
import {
    SearchField as SourceSearchField,
    SearchOverlay as SourceSearchOverlay,
} from 'SourceComponent/SearchField/SearchField.component';
import { scrollToTop } from 'Util/Browser';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import './SearchField.override.style';

export const SearchOverlay = SourceSearchOverlay;

/** @namespace Pwa/Component/SearchField/Component/SearchField */
export class SearchField extends SourceSearchField {
    renderSearchIcon() {
        const { isActive } = this.props;

        if (isActive) {
            return (
                <div
                    block="SearchField"
                    elem="CloseIcon"
                    role="button"
                    tabIndex="0"
                    onClick={this.closeSearch}
                    aria-label={__('Close')}
                >
                    <Icons name="close" />
                </div>
            );
        }

        return (
            <div
                block="SearchField"
                elem="SearchIcon"
                role="button"
                tabIndex="0"
                onClick={this.openSearch}
                aria-label={__('Search')}
            >
                <Icons name="search" fill="#191C1D" />
            </div>
        );
    }

    onSearchEnterPress(e) {
        const { searchCriteria, hideActiveOverlay, onSearchBarChange } = this.props;
        const search = searchCriteria.trim().replace(/\s/g, '+').replace(/&/g, '%26');
        const trimmedSearch = searchCriteria.trim();

        if (e.key === 'Enter' && trimmedSearch !== '') {
            history.push(appendWithStoreCode(`/search/${search}`));
            hideActiveOverlay();
            onSearchBarChange({ target: { value: '' } });
            this.searchBarRef.current.blur();
            this.closeSearch();
            scrollToTop();
        }
    }

    renderSearch() {
        const { searchCriteria, onSearchBarFocus, isActive, device } = this.props;

        return (
            <div block="SearchField" elem="SearchInnerWrapper">
                <input
                    id="search-field"
                    ref={this.searchBarRef}
                    block="SearchField"
                    elem="Input"
                    onFocus={onSearchBarFocus}
                    onChange={this.handleChange}
                    onKeyDown={this.onSearchEnterPress}
                    value={searchCriteria}
                    mods={{ isActive, isOpenOverlay: !!searchCriteria }}
                    placeholder={__('Search products')}
                    autoComplete="off"
                    aria-label={__('Search')}
                />
                {this.renderSearchIcon()}
                <Suspense fallback={this.renderOverlayFallback()}>
                    <SearchOverlay
                        isHideOverlay={!device.isMobile}
                        clearSearch={this.clearSearch}
                        searchCriteria={searchCriteria}
                    />
                </Suspense>
            </div>
        );
    }

    render() {
        const {
            isVisible,
            isActive,
            device: { isMobile },
        } = this.props;

        return (
            <div block="SearchField" mods={{ isVisible, isActive }}>
                <ClickOutside onClick={!isMobile && isVisible && this.closeSearch.bind(this)}>
                    <div block="SearchField" elem="Wrapper">
                        {this.renderSearch()}
                    </div>
                </ClickOutside>
            </div>
        );
    }
}

export default SearchField;
