import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';

import {
    CART_EVENT_ID,
    ORGANIZATION_ID,
    SALE_EVENT_ID,
    TRADEDOUBLER_COOKIE_NAME,
    TRADEDOUBLER_WRAP_ID,
} from './HeaderHelmet.config';
/** @namespace Pwa/Component/HeaderHelmet/Component/HeaderHelmet */
export class HeaderHelmet extends PureComponent {
    getCookieValue(name) {
        // eslint-disable-next-line prefer-template
        return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';
    }

    renderCartPixel() {
        const { isCartPage } = this.props;
        const isTDAffiliatedParam = this.getCookieValue(TRADEDOUBLER_COOKIE_NAME);

        if (!isCartPage || !isTDAffiliatedParam) {
            return null;
        }

        const cartObjectContent = `var orderNumber = "auto";

        var organization=${ORGANIZATION_ID};
        var event=${CART_EVENT_ID};
        
        function getCookie(name){
            var dc = document.cookie;
            var prefix = name + "=";
            var begin = dc.indexOf("; " + prefix);
            if (begin == -1) {
                begin = dc.indexOf(prefix);
                if (begin != 0) return null;
            } else {
                begin += 2;
            }
            var end = document.cookie.indexOf(";", begin);
            if (end == -1) {
                end = dc.length;
            }
            return unescape(dc.substring(begin + prefix.length, end));
        }
        var tduid = getCookie("TRADEDOUBLER");
        /***** IMPORTANT: *****/
        /***** Please consult Tradedoubler before modifying the code below this line. *****/
        //
        var trackBackUrl = "https://tbl.tradedoubler.com/report" + "?organization=" + organization + "&event=" + event + "&leadNumber=" + orderNumber + "&tduid=" + tduid + "&type=iframe";
        //
        function prepareFrame(tburl){
            ifrm = document.createElement("IFRAME"); 
            ifrm.setAttribute("src", tburl); 
            ifrm.style.width = 1+"px"; 
            ifrm.style.height = 1+"px";
            ifrm.style.border = "none";
            document.body.appendChild(ifrm); 
        }         
        prepareFrame(trackBackUrl);`;

        return (
            <script defer type="text/javascript">
                {cartObjectContent}
            </script>
        );
    }

    renderSalePixel() {
        const { isSuccessPage } = this.props;
        const isTDAffiliatedParam = this.getCookieValue(TRADEDOUBLER_COOKIE_NAME);
        const saleTDConfigObject = JSON.parse(sessionStorage.getItem('saleTDConfigData'));
        const sessionOrderID = sessionStorage.getItem('ORDER_ID');

        if (!isSuccessPage || !isTDAffiliatedParam) {
            return null;
        }

        const orderID = saleTDConfigObject?.orderNumber || sessionOrderID;
        const saleObjectContent = `/*
        * Tradedoubler conversion pixel
        */
       //
       // A unique identifier for the transaction. For a sale, this is typically the order number.
       var orderNumber = ${`"${orderID}"` || `''`};
       //
       // Value of the sale.
       var orderValue = ${`"${saleTDConfigObject?.orderValue}"` || `''`};
       //
       // Currency of the sale.
       var currency = ${`"${saleTDConfigObject?.currency}"` || `''`};
       //
       // Transmit a list of items ordered in the reportInfo parameter.
       var reportInfo = ${`"${saleTDConfigObject?.reportInfo}"` || `''`};
       //
       //Set value of extid. This must be the email address hashed using SHA-256.
       var extid = ${`"${saleTDConfigObject?.extid}"` || `''`};
       //
       //Set the value of the external ID type. If an email address is used for extid, exttype=1. If an internal user ID is used, exttype=0.
       var exttype = ${`"${saleTDConfigObject?.exttype}"` || `''`};
       //
       // Voucher code used by user.
       var voucher = ${`"${saleTDConfigObject?.voucher}"` || `''`};
       //
       // Event ID
       var event=${SALE_EVENT_ID};
       //
       // Your organization ID
       var organization=${ORGANIZATION_ID};
       //
       reportInfo = encodeURIComponent(reportInfo);
       //
       function getCookie(name) {
           var dc = document.cookie;
           var prefix = name + "=";
           var begin = dc.indexOf("; " + prefix);
           if (begin == -1) {
               begin = dc.indexOf(prefix);
               if (begin != 0) return null;
           } else {
               begin += 2;
           }
           var end = document.cookie.indexOf(";", begin);
           if (end == -1) {
               end = dc.length;
           }
           return unescape(dc.substring(begin + prefix.length, end));
       }
       var tduid = getCookie("TRADEDOUBLER");
       /***** IMPORTANT: *****/
       /***** Please consult Tradedoubler before modifying the code below this line. *****/
       //
       var trackBackUrl = "https://tbs.tradedoubler.com/report" + "?organization=" + organization + "&event=" + event + "&orderNumber=" + orderNumber + "&orderValue=" + orderValue + "&currency=" + currency + "&voucher=" + voucher + "&tduid=" + tduid + "&extid=" + extid + "&exttype=" + exttype + "&type=iframe&reportInfo=" + reportInfo;
       //
       function prepareFrame(tburl){
           ifrm = document.createElement("IFRAME"); 
           ifrm.setAttribute("src", tburl); 
           ifrm.style.width = 1+"px"; 
           ifrm.style.height = 1+"px";
           ifrm.style.border = "none";
           document.body.appendChild(ifrm); 
       }         
       prepareFrame(trackBackUrl);
       //
       (function() {
           if (tduid) {
               var img = document.createElement('img');
               img.src = 'https://imgstatic.eu/report?o=' + organization + '&e=' + event + '&ordnum=' + orderNumber + '&ordval=' + orderValue + '&curr=' + currency + '&tduid=' + tduid + '&reportInfo=' + reportInfo;
               document.body.appendChild(img);
           }
       })();`;

        return (
            <script defer type="text/javascript">
                {saleObjectContent}
            </script>
        );
    }

    renderConfigObject() {
        const {
            configData: { configData: { products = [], orderId = '', orderValue = '', pageType = 'other' } = {} } = {},
            isSalePixel,
        } = this.props;

        if (isSalePixel) {
            if (window.TDConf?.Config) {
                const orderID = sessionStorage.getItem('ORDER_ID');
                window.TDConf.Config.orderId = orderID;
            }

            return null;
        }

        const objectContent = `function setCookie(name, value, expires, path, domain, secure) {
            var today = new Date();
            today.setTime( today.getTime() );
            if ( expires ){
                expires = expires * 1000 * 60 * 60 * 24;
            }
            var expires_date = new Date( today.getTime() + (expires) );
            document.cookie= name + "=" + escape(value) +
            ((expires) ? "; expires=" + expires_date.toGMTString() : "") +
            ((path) ? "; path=" + path : "") +
            ((domain) ? "; domain=" + domain : "") +
            ((secure) ? "; secure" : "");
        }
        function getVar(name) {
            get_string = document.location.search;
            return_value = '';
            do {
                name_index = get_string.indexOf(name + '=');
                if(name_index != -1) {
                    get_string = get_string.substr(name_index + name.length + 1, get_string.length - name_index);
                    end_of_value = get_string.indexOf('&');
                    if(end_of_value != -1) {
                        value = get_string.substr(0, end_of_value);
                    } else {
                        value = get_string;
                    }
                    if(return_value == '' || value == '') {
                        return_value += value;
                    } else {
                        return_value += ', ' + value;
                    }
                }
            } while(name_index != -1) {
                space = return_value.indexOf('+');
            }
            while(space != -1){
                return_value = return_value.substr(0, space) + ' ' + return_value.substr(space + 1, return_value.length);
                space = return_value.indexOf('+');
            }
            return(return_value);
        }
        var mytduid = getVar('tduid');
        if (mytduid != '') {
            setCookie('TRADEDOUBLER', mytduid, 365, '/', window.location.hostname);
        }


        var TDConf = TDConf || {};
        TDConf.Config = {
            products: ${products?.length ? JSON.stringify(products) : '[]'},
            orderId: ${`"${orderId}"` || `''`},
            orderValue:${`"${orderValue}"` || `''`},
            pageType:${`"${pageType}"` || `"other"`}, 
        }`;

        return (
            <script defer type="text/javascript">
                {objectContent}
            </script>
        );
    }

    renderContent() {
        return (
            <Helmet>
                {this.renderConfigObject()}
                <script defer type="text/javascript" src={`//wrap.tradedoubler.com/wrap?id=${TRADEDOUBLER_WRAP_ID}`} />
                {this.renderCartPixel()}
                {this.renderSalePixel()}
            </Helmet>
        );
    }

    render() {
        return (
            <div block="HeaderHelmet" elem="Wrapper">
                {this.renderContent()}
            </div>
        );
    }
}

export default HeaderHelmet;
