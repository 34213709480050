import { connect } from 'react-redux';

import { WISHLIST } from 'Component/Header/Header.config';
import {
    DEFAULT_NAVIGATION_TABS_STATE as SOURCE_DEFAULT_NAVIGATION_TABS_STATE,
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    NavigationTabsContainer as SourceNavigationTabsContainer,
} from 'SourceComponent/NavigationTabs/NavigationTabs.container';
import { scrollToTop } from 'Util/Browser';
import browserHistory from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import { WISHLIST_TAB } from './NavigationTabs.config';

export const DEFAULT_NAVIGATION_TABS_STATE = SOURCE_DEFAULT_NAVIGATION_TABS_STATE;

/** @namespace Pwa/Component/NavigationTabs/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    compareTotals: state.ProductCompareReducer.count,
    wishlistProducts: state.WishlistReducer.productsInWishlist,
});

export { mapDispatchToProps };

/** @namespace Pwa/Component/NavigationTabs/Container/NavigationTabsContainer */
export class NavigationTabsContainer extends SourceNavigationTabsContainer {
    __construct(props) {
        super.__construct(props);

        this.routeMap['/wishlist'] = {
            name: WISHLIST_TAB,
        };
    }

    containerFunctions = {
        ...this.containerFunctions,
        onWishlistButtonClick: this.onWishlistButtonClick.bind(this),
    };

    componentDidUpdate(...args) {
        super.componentDidUpdate(...args);

        const [prevProps] = args;
        const { compareTotals } = this.props;

        if (compareTotals !== prevProps.compareTotals) {
            document.documentElement.style.setProperty(
                '--navigation-compare-section-height',
                compareTotals > 0 ? '44px' : '0px'
            );
        }
    }

    hideNavigationTabs() {}

    showNavigationTabs() {}

    containerProps() {
        const { compareTotals, wishlistProducts } = this.props;

        return {
            ...super.containerProps(),
            compareTotals,
            wishlistTotals: Object.keys(wishlistProducts)?.length || 0,
        };
    }

    onWishlistButtonClick() {
        const { pathname } = location;

        if (pathname !== appendWithStoreCode(`/${WISHLIST}`)) {
            scrollToTop();
            browserHistory.push(appendWithStoreCode(`/${WISHLIST}`));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationTabsContainer);
