/* eslint-disable indent */
import PropTypes from 'prop-types';

import Ceneo from 'Component/Ceneo';
import ChevronIcon from 'Component/ChevronIcon';
import { LEFT } from 'Component/ChevronIcon/ChevronIcon.config';
import ClickOutside from 'Component/ClickOutside';
import {
    ACC_CREATE_LINK,
    ACC_FORGOT_PASS_LINK,
    ACC_LOGIN_LINK,
    CART_OVERLAY,
    CUSTOMER_WISHLIST,
    FILTER,
    LOGO_ALT,
    MENU,
    SEARCH,
    WISHLIST_LINK,
} from 'Component/Header/Header.config';
import HeaderFollowing from 'Component/HeaderFollowing';
import HeaderHelmet from 'Component/HeaderHelmet';
import Icons from 'Component/Icons';
import Link from 'Component/Link';
import Menu from 'Component/Menu';
import OfflineNotice from 'Component/OfflineNotice';
import { CALCULATOR_UNIT } from 'Component/ProductActions/ProductActions.config';
import SearchField from 'Component/SearchField';
import ShareIcon from 'Component/ShareIcon';
import Shoppers from 'Component/Shoppers';
import {
    CartOverlay as SourceCartOverlay,
    Header as SourceHeader,
    MyAccountOverlay as SourceMyAccountOverlay,
} from 'SourceComponent/Header/Header.component';
import { isSignedIn } from 'Util/Auth';
import { isCrawler, isSSR } from 'Util/Browser';
import { divideValues } from 'Util/Product/Calculator';

import scssVariables from '../../style/abstract/_export.module.scss';

import './Header.override.style';

export const CartOverlay = SourceCartOverlay;
export const MyAccountOverlay = SourceMyAccountOverlay;

/** @namespace Pwa/Component/Header/Component/Header */
export class Header extends SourceHeader {
    static propTypes = {
        ...SourceHeader.propTypes,
        totalItems: PropTypes.number.isRequired,
    };

    static defaultProps = {
        ...SourceHeader.defaultProps,
        logo_alt: LOGO_ALT,
    };

    renderMap = {
        cancel: this.renderCancelButton.bind(this),
        back: this.renderBackButton.bind(this),
        close: this.renderCloseButton.bind(this),
        logo: this.renderLogo.bind(this),
        search: this.renderSearchField.bind(this),
        account: this.renderAccount.bind(this),
        compare: this.renderComparePageButton.bind(this),
        wishlist: this.renderWishlistPageButton.bind(this),
        minicart: this.renderMinicart.bind(this),
        share: this.renderShareWishListButton.bind(this),
        ok: this.renderOkButton.bind(this),
    };

    state = {
        isSearchFieldVisible: true,
    };

    __construct(props) {
        super.__construct(props);

        this.stateMap = {
            ...this.stateMap,
            [FILTER]: {
                close: true,
                title: true,
            },
        };
    }

    calculateMiniCartQty(items) {
        // eslint-disable-next-line fp/no-let
        let quantity = 0;

        // eslint-disable-next-line array-callback-return
        items.map(({ product, qty }) => {
            if (product?.product_unit === CALCULATOR_UNIT) {
                const vp_items_ratio = product?.vp_items;
                const productQuantity = divideValues(qty, vp_items_ratio);

                quantity += +productQuantity;
            } else {
                quantity += +qty;
            }
        });

        return quantity;
    }

    toggleSearchField(id, isLayerVisible) {
        const { device } = this.props;

        if (id === 'HEADER' && !device.isMobile) {
            this.setState({ isSearchFieldVisible: !isLayerVisible });
            // !!! HACK - because this.renderAccount() doesn't react on state change
            this.forceUpdate();
            // !!!
        }
    }

    renderMinicart(isVisible = false, key, isOnFloatingHeader = false) {
        const { isSearchFieldVisible } = this.state;
        const {
            onMinicartOutsideClick,
            isCheckout,
            device: { isMobile },
        } = this.props;

        if (isMobile || isCheckout) {
            return null;
        }

        if (!isSearchFieldVisible && !isOnFloatingHeader) {
            return null;
        }

        return (
            <ClickOutside onClick={onMinicartOutsideClick} key="minicart">
                <div block="Header" elem="Button" mods={{ isVisible, type: 'minicart' }}>
                    {this.renderMinicartButton()}
                    {this.renderMinicartOverlay()}
                </div>
            </ClickOutside>
        );
    }

    renderMinicartItemsQty() {
        const {
            cartTotals: { items, items_qty },
        } = this.props;

        if (!items_qty) {
            return null;
        }

        const isProductWithCalculatorInBasket = items
            .map(({ product: { product_unit } }) => {
                if (product_unit === CALCULATOR_UNIT) {
                    return true;
                }

                return false;
            })
            .includes(true);

        const cartQuantity = isProductWithCalculatorInBasket ? this.calculateMiniCartQty(items) : items_qty;

        return (
            <span aria-label="Items in cart" block="Header" elem="MinicartItemCount">
                {cartQuantity}
            </span>
        );
    }

    renderMinicartButton() {
        const { onMinicartButtonClick } = this.props;

        return (
            <button
                block="Header"
                elem="MinicartButtonWrapper"
                tabIndex="0"
                onClick={onMinicartButtonClick}
                aria-label={__('Cart')}
            >
                <div block="Header" elem="IconWrapper">
                    <Icons
                        block="Header"
                        elem="Icon"
                        mods={{ bag: true }}
                        name="bag"
                        fill={scssVariables.colorPrimary}
                    />
                    <span block="Header" elem="IconLabel">
                        {__('Cart')}
                    </span>
                </div>
                {this.renderMinicartItemsQty()}
            </button>
        );
    }

    renderLogo(isVisible = false) {
        const {
            isLoading,
            device: { isMobile },
        } = this.props;

        if (isLoading) {
            return null;
        }

        return (
            <Link
                to="/"
                aria-label="Go to homepage by clicking on ScandiPWA logo"
                aria-hidden={!isVisible}
                tabIndex={isVisible ? 0 : -1}
                block="Header"
                elem="LogoWrapper"
                mods={{ isVisible: isVisible || isMobile }}
                key="logo"
            >
                {this.renderLogoImage()}
            </Link>
        );
    }

    renderCloseButton(isVisible = false) {
        const {
            onCloseButtonClick,
            device: { isMobile },
        } = this.props;

        if (!isMobile) {
            return null;
        }

        return (
            <button
                key="close"
                block="Header"
                elem="Button"
                mods={{ type: 'close', isVisible }}
                onClick={onCloseButtonClick}
                aria-label="Close"
                aria-hidden={!isVisible}
                tabIndex={isVisible ? 0 : -1}
            >
                <ChevronIcon direction={LEFT} />
            </button>
        );
    }

    renderComparePageButton() {
        const { device: { isMobile } = {}, isCheckout } = this.props;

        if (isCheckout || isMobile) {
            return null;
        }

        return (
            <div block="Header" elem="CompareButtonWrapper" key="compare">
                <Link
                    to="compare"
                    key="compare"
                    block="Header"
                    elem="Button"
                    mods={{ type: 'compare' }}
                    aria-label={__('Compare')}
                >
                    <div block="Header" elem="IconWrapper">
                        <Icons
                            block="Header"
                            elem="Icon"
                            mods={{ scale: true }}
                            name="scale"
                            fill={scssVariables.colorPrimary}
                        />
                        <span block="Header" elem="IconLabel">
                            {__('Compare')}
                        </span>
                    </div>
                    {this.renderCompareCount()}
                </Link>
            </div>
        );
    }

    renderAccountButton() {
        const { onMyAccountButtonClick, device, firstname } = this.props;
        const showWelcomeMessage = isSignedIn() && firstname;

        if (device.isMobile) {
            return null;
        }

        return (
            <button
                block="Header"
                elem="MyAccountWrapper"
                tabIndex="0"
                onClick={onMyAccountButtonClick}
                aria-label={showWelcomeMessage ? __('Welcome, %s!', firstname) : __('Profile')}
                id="myAccount"
            >
                <div block="Header" elem="IconWrapper">
                    <Icons
                        block="Header"
                        elem="Icon"
                        mods={{ user: true }}
                        name="user"
                        fill={scssVariables.colorPrimary}
                    />
                    <span block="Header" elem="IconLabel">
                        {showWelcomeMessage ? __('Welcome, %s!', firstname) : __('Profile')}
                    </span>
                </div>
            </button>
        );
    }

    renderAccount(isVisible = false, key, isOnFloatingHeader = false) {
        const { isSearchFieldVisible } = this.state;
        const {
            onMyAccountOutsideClick,
            isCheckout,
            device: { isMobile },
        } = this.props;

        if ((isMobile && !isCheckout) || isCheckout) {
            return null;
        }

        if (!isSearchFieldVisible && !isOnFloatingHeader) {
            return null;
        }

        return (
            <div key="account" block="Header" elem="MyAccountContainer">
                <ClickOutside onClick={onMyAccountOutsideClick}>
                    <div aria-label="My account" block="Header" elem="MyAccount">
                        {this.renderAccountButton(isVisible)}
                        {this.renderAccountOverlay()}
                    </div>
                </ClickOutside>
            </div>
        );
    }

    renderCeneo() {
        if (isCrawler()) {
            return null;
        }

        return <Ceneo isHeader />;
    }

    renderWishlistPageButton() {
        const {
            isCheckout,
            device: { isMobile },
        } = this.props;

        if (isMobile || isCheckout) {
            return null;
        }

        return (
            <div block="Header" elem="Button" mods={{ type: 'wishlist' }} key="wishlist">
                <Link
                    to={WISHLIST_LINK}
                    key="wishlist"
                    block="Header"
                    elem="WishlistButtonWrapper"
                    aria-label={__('Wishlist Page')}
                >
                    <div block="Header" elem="IconWrapper">
                        <Icons
                            block="Header"
                            elem="Icon"
                            mods={{ heart: true }}
                            name="heart"
                            fill={scssVariables.colorPrimary}
                        />
                        <span block="Header" elem="IconLabel">
                            {__('Wishlist')}
                        </span>
                    </div>
                    {this.renderWishlistCount()}
                </Link>
            </div>
        );
    }

    renderWishlistCount() {
        const { wishlistTotals, Loading } = this.props;

        if (!wishlistTotals || Loading === true) {
            return null;
        }

        return (
            <span aria-label="Items in wishlist" block="Header" elem="CompareCount">
                {wishlistTotals}
            </span>
        );
    }

    renderHeaderFollowing() {
        if (isCrawler()) {
            return null;
        }

        return (
            <HeaderFollowing
                renderLogo={this.renderLogo.bind(this)}
                renderSearchField={this.renderSearchField.bind(this)}
                renderMenu={this.renderMenu.bind(this)}
                renderIcons={[
                    this.renderAccount.bind(this),
                    this.renderComparePageButton.bind(this),
                    this.renderWishlistPageButton.bind(this),
                    this.renderMinicart.bind(this),
                ]}
                onEmitVisibility={this.toggleSearchField.bind(this)}
            />
        );
    }

    renderMenu({ prefixMenu } = {}) {
        const {
            isCheckout,
            device: { isMobile },
        } = this.props;

        if (isMobile || isCheckout) {
            return null;
        }

        return (
            <div block="Header" elem="Menu">
                <Menu prefixMenu={prefixMenu} />
            </div>
        );
    }

    renderMenuMobileTitle() {
        const {
            navigationState: { name, title },
        } = this.props;

        if (name === FILTER) {
            if (!title) {
                return null;
            }

            return (
                <h2 key="title" block="Menu" elem="Title">
                    {title}
                </h2>
            );
        }

        if (name === MENU || name === SEARCH) {
            return (
                <h2 key="title" block="Menu" elem="Title">
                    {__('Main menu')}
                </h2>
            );
        }

        return null;
    }

    renderShareWishListButton(isVisible = false) {
        const { isWishlistLoading, shareWishlist, wishlistTotals } = this.props;

        if (!wishlistTotals) {
            return null;
        }

        return (
            <button
                key="share"
                block="Header"
                elem="Button"
                mods={{ type: 'share', isVisible }}
                onClick={shareWishlist}
                aria-label="Share"
                aria-hidden={!isVisible}
                disabled={isWishlistLoading}
            >
                <ShareIcon />
            </button>
        );
    }

    renderNavigationState() {
        const {
            device: { isMobile } = {},
            navigationState: { name, hiddenElements = [] },
        } = this.props;

        const source = this.stateMap[name] ? this.stateMap[name] : this.stateMap[this.defaultStateName];

        return Object.entries(this.renderMap)
            .filter(([key]) => (isMobile ? (name === FILTER ? source[key] : true) : true))
            .map(([key, renderFunction]) => renderFunction(source[key] && !hiddenElements.includes(key), key));
    }

    renderSearchField(isVisible = false) {
        const { isSearchFieldVisible } = this.state;
        const {
            searchCriteria,
            onSearchOutsideClick,
            onSearchBarFocus,
            onSearchBarChange,
            onClearSearchButtonClick,
            navigationState: { name },
            isCheckout,
            hideActiveOverlay,
        } = this.props;

        if (isCheckout) {
            return null;
        }

        return (
            <SearchField
                key="search"
                searchCriteria={searchCriteria}
                onSearchOutsideClick={onSearchOutsideClick}
                onSearchBarFocus={onSearchBarFocus}
                onSearchBarChange={onSearchBarChange}
                onClearSearchButtonClick={onClearSearchButtonClick}
                isVisible={isVisible && isSearchFieldVisible}
                isActive={name === SEARCH}
                hideActiveOverlay={hideActiveOverlay}
            />
        );
    }

    render() {
        const { stateMap } = this;
        const {
            navigationState: { name, isHiddenOnMobile = false },
            isCheckout,
            device: { isMobile },
            onCloseButtonClick,
            isCategoryPage,
            configData,
            isCartPage,
            isSuccessPage,
            isPaymentPage,
        } = this.props;

        const url = location.pathname;
        const isMobileAccountHeader =
            (url.indexOf(ACC_LOGIN_LINK) > -1 ||
                url.indexOf(ACC_CREATE_LINK) > -1 ||
                url.indexOf(ACC_FORGOT_PASS_LINK) > -1) &&
            isMobile;

        if (!isMobile) {
            // hide edit button on desktop
            stateMap[CUSTOMER_WISHLIST].edit = false;
            stateMap[CUSTOMER_WISHLIST].share = false;
            stateMap[CART_OVERLAY].edit = false;
        }

        return (
            <>
                {configData ? (
                    <HeaderHelmet configData={configData} isSuccessPage={isSuccessPage} isCartPage={isCartPage} />
                ) : null}
                {this.renderCeneo()}
                <Shoppers configData={configData} isSuccessPage={isSuccessPage} isPaymentPage={isPaymentPage} />
                <section block="Header" elem="Wrapper" mods={{ isPrerendered: isSSR() || isCrawler(), isCheckout }}>
                    <header
                        block="Header"
                        mods={{ name, isHiddenOnMobile, isCheckout, isCategoryPage }}
                        mix={{ block: 'FixedElement', elem: 'Top' }}
                    >
                        {isMobileAccountHeader ? (
                            <nav block="Header" elem="Nav" mix={{ block: 'Header', elem: 'NavAuth' }}>
                                <div block="Header" elem="BarTitle">
                                    {this.renderNavigationState()}
                                </div>
                                <div block="Header" elem="BarExitButton" onClick={onCloseButtonClick}>
                                    <Icons name="close" fill="#191C1D" />
                                </div>
                            </nav>
                        ) : (
                            <nav block="Header" elem="Nav">
                                {this.renderMenuMobileTitle()}
                                {this.renderNavigationState()}
                            </nav>
                        )}
                        {this.renderMenu()}
                    </header>
                    <OfflineNotice />
                    {this.renderHeaderFollowing()}
                </section>
            </>
        );
    }
}

export default Header;
